import tenants from '../tenants.json'
import { msalApp, loginObj } from './auth.utils'
import { localApi } from './local.api.service'
import store from '@/store'

export default class AuthService {
  constructor (tenant = null) {
    if (!tenant) {
      tenant = this.getCachedTenant()
    }
    if (tenant) {
      this.createMsalInstance(tenant)
    }
  }

  /**
   * Creates an  instanceof the msal application
   * @param {String} tenant
   */
  createMsalInstance (tenant = this.getCachedTenant()) {
    if (!tenant) {
      tenant = this.getCachedTenant()
    }
    const currentUri = window.location
    const loginUri = 'https://' + currentUri.host + '/login' // LOGIN URI/REDIRECT URI
    const logoutUri = 'https://' + currentUri.host + '/login' // LOGOUT URI/LOGOUT REDIRECT URI
    let foundTenant = this.searchForTenant(tenant)
    let tenantWasFound = foundTenant !== null
    if (!tenantWasFound) {
      for (const p in tenants) {
        if (tenants[p] === tenant) {
          tenantWasFound = true
          foundTenant = tenant
        }
      }
    }
    foundTenant = tenantWasFound ? foundTenant : 'organizations'
    console.log('creating new msalapp ' + foundTenant + ' login: ' + loginUri + ' logout: ' + logoutUri)
    this.app = msalApp(foundTenant, loginUri, logoutUri)
    return this.app
  }

  /**
   * @returns tenant if the tenant is found in tenants.json, else null
   * @param {String} tenant
   */
  searchForTenant (tenant) {
    if (tenant && tenants) {
      if (tenants[tenant]) {
        return tenants[tenant]
      }
    }
    return null
  }

  /**
   * Handels the redirect from the login process
   * @returns user if user is found, else null
   * @param {String} tenant
   */
  async handleRedirect (tenant) {
    try {
      this.createMsalInstance(tenant)
      const redirectResponse = await this.app.handleRedirectPromise()
      if (this.isAuthenticatedWithMsal()) {
        if (redirectResponse !== null) {
          localStorage.setItem('tenantId', redirectResponse.tenantId)
        }
        const user = await this.getUser()
        if (user) {
          store.dispatch('user/setUser', JSON.stringify(user)) // Set user to localstorage
          return user
        }
        console.error('returning null')
        return redirectResponse
      }
    } catch (err) {
      if (err.errorCode === 'user_cancelled') {
        throw err
      }
      console.error('Feil ved inlogging', err)
      return null
    }
  }

  /**
   * Initiates a login process by redirecting the user to the authentication page
   * @param {String} tenant
   */
  async login (tenant) {
    this.createMsalInstance(tenant)
    localStorage.setItem('tenantString', tenant)
    this.app.loginRedirect(loginObj)
  }

  async acquireInternalApiToken () {
    const _self = this
    const silentRequest = {
      account: this.getMsalUser(),
      scopes: [process.env.VUE_APP_AUTH_API_Scope],
      authority: '',
      forceRefresh: false,
      correlationId: ''
    }
    return _self.app.acquireTokenSilent(silentRequest).then((accessTokenResponse) => {
      return accessTokenResponse.accessToken
    }).catch(function (error) {
      // Acquire token silent failure, and send an interactive request
      console.error('Error getting token silent', error)
      return _self.app.acquireTokenRedirect(silentRequest).catch(function async (error) {
        console.error(JSON.stringify(error))
        _self.login()
        return null
      })
    })
  }

  getCurrentAuthority () {
    return this.app.config.auth.authority
  }

  async logout () {
    store.dispatch('user/removeUser')
    localStorage.removeItem('tenantString')
    await this.app.logoutRedirect()
  }

  clearCache () {
    this.app.clearCache()
  }

  /**
   * @returns Full user object with information from local db and microsoft ad
   */
  async getUser () {
    const msalUser = this.getMsalUser()
    const localUser = await localApi.getUser()
    return this.mapToUser(localUser, msalUser)
  }

  /**
   * @returns Current user or null if the user is not present in cache
   */
  getMsalUser () {
    if (!this.app) {
      return null
    }
    const currentAccounts = this.app.getAllAccounts()
    if (!currentAccounts || currentAccounts.length === 0) {
      //  No user signed in
      return null
    } else if (currentAccounts.length > 1) {
      // More than one user signed in, find desired user with getAccountByUsername(username)
      console.log('Many accounts logged inn... taking first')
      return currentAccounts[0]
    } else {
      return currentAccounts[0]
    }
  }

  /**
   * Maps a user to a general user-object
   * @param {*} localUser User from local database
   * @param {*} msalUser User from Microsoft AD
   * @returns User | null
   */
  mapToUser (localUser, msalUser) {
    if (localUser && msalUser) {
      return {
        name: localUser.name ? localUser.name : msalUser.name,
        email: localUser.email ? localUser.email : msalUser.userName,
        role: localUser.role ? localUser.role : null,
        areas: localUser.areas ? localUser.areas : [],
        id: localUser.id ? localUser.id : null,
        oid: localUser.oid ? localUser.oid : msalUser.accountIdentifier,
        isAdmin: localUser.role ? localUser.role.includes('admin') : false,
        tid: (msalUser.tenantId) ? msalUser.tenantId : null,
        exp: (msalUser.idToken && msalUser.idToken.exp) ? msalUser.idToken.exp : null
      }
    }
    return null
  }

  /**
   * @returns true if the user is authenticated with microsoft AD
   */
  isAuthenticatedWithMsal () {
    return this.getMsalUser() !== null
  }

  getCachedTenant () {
    if (localStorage.tenantId !== undefined) {
      if (localStorage.tenantId !== '') {
        return localStorage.tenantId
      }
    }
  }
}
